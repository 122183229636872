/* eslint-disable max-len */

// List of environment variables for entire project. Prefix all new variables with ENV_
export const ENV_URL_HOME = process.env.NEXT_PUBLIC_URL_HOME;
export const ENV_NEWRELIC_BROWSER_ACCOUNT_ID = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_ACCOUNT_ID;
export const ENV_NEWRELIC_BROWSER_AGENT_ID = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_AGENT_ID;
export const ENV_NEWRELIC_BROWSER_APPLICATION_ID = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_APPLICATION_ID;
export const ENV_NEWRELIC_BROWSER_TRUST_KEY = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_TRUST_KEY;
export const ENV_NEWRELIC_BROWSER_LICENSE_KEY = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_LICENSE_KEY;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '';
export const ENV_ROLLBAR_ENV = process.env.NEXT_PUBLIC_ROLLBAR_ENV || 'development';
export const ENV_ROLLBAR_CLIENT_TOKEN = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN || '';
export const ENV_SEGMENT_KEY = process.env.NEXT_PUBLIC_ENV_SEGMENT_KEY || '';
